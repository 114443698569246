
import {
  Component,
  defineComponent, PropType,
} from 'vue';

import { IRecommendation } from '@/services/api/lxp-assignment/types';

import MandatoryRecommendationCard from '@/components/tracks/MandatoryRecommendationCard/MandatoryRecommendationCard.vue';
import NotMandatoryRecommendationCard from '@/components/tracks/NotMandatoryRecommendationCard/NotMandatoryRecommendationCard.vue';
import { IRecommendationCardClickPayload } from '@/domains/track';
import { IAssignment } from '@/domains/assignment';

// Количество карточек в строке
const DEFAULT_ROW_CARDS_COUNT: number = 4;
export default defineComponent({
  name: 'RecommendationSection',
  components: {
    MandatoryRecommendationCard,
    NotMandatoryRecommendationCard,
  },
  props: {
    recommendations: {
      type: Array as PropType<IRecommendation[]>,
      required: true,
    },
    isMandatoryRecommendations: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visibleCards: DEFAULT_ROW_CARDS_COUNT,
    };
  },
  computed: {
    canShowCards(): number {
      return this.restOfCards >= DEFAULT_ROW_CARDS_COUNT ? DEFAULT_ROW_CARDS_COUNT : this.restOfCards;
    },
    restOfCards(): number {
      return this.recommendations?.length - this.visibleCards;
    },
    visibleRecommendations(): IRecommendation[] {
      return this.recommendations.slice(0, this.visibleCards);
    },
    localeParams(): { count: number, total: number} {
      return {
        count: this.canShowCards,
        total: this.restOfCards,
      };
    },
    translations(): Record<string, string> {
      return this.isMandatoryRecommendations
        ? {
          title: this.$t('RecommendationSection.mandatory.title'),
          description: this.$t('RecommendationSection.mandatory.description'),
        }
        : {
          title: this.$t('RecommendationSection.recommended.title'),
          description: this.$t('RecommendationSection.recommended.description'),
        };
    },

    component(): Component {
      return this.isMandatoryRecommendations
        ? MandatoryRecommendationCard
        : NotMandatoryRecommendationCard;
    },
  },
  methods: {
    onAssignClickHandler({ id }: { id: IRecommendation['atomCatalogueId'] }) {
      this.$emit('click:assign', id);
    },
    onRemoveAssignClickHandler({ id }: { id: IAssignment['assignmentUserId'] }) {
      this.$emit('click:remove-assign', id);
    },
    showMore() {
      if (this.restOfCards <= DEFAULT_ROW_CARDS_COUNT) {
        this.visibleCards += this.restOfCards;
      } else {
        this.visibleCards += DEFAULT_ROW_CARDS_COUNT;
      }
    },
    onClickGoToHandler(payload: IRecommendationCardClickPayload) {
      this.$emit('click:go-to-mandatory-atom', payload);
    },
  },
});
