
import {
  defineComponent, PropType,
} from 'vue';
import { IAtom, AtomEntity } from '@/domains/atom';
import { IMultileveltestContent } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import MultileveltestResultInfo from '@/components/multileveltest/MultileveltestResultInfo';
import RecommendationSection from '@/components/recommendations/RecommendationSection';
import { IRecommendation } from '@/services/api/lxp-assignment/types';
import { IRecommendationCardClickPayload } from '@/domains/track';
import { IAssignment } from '@/domains/assignment';

export default defineComponent({
  name: 'MultileveltestResultView',

  components: {
    MultileveltestResultInfo,
    RecommendationSection,
  },

  props: {
    content: {
      type: Object as PropType<IMultileveltestContent>,
      required: true,
    },
    atom: {
      type: Object as PropType<IAtom>,
      default: () => (new AtomEntity({})),
    },
    recommendations: {
      type: Array as PropType<IRecommendation[]>,
      default: () => [],
    },
    isMandatoryRecommendation: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const onAssignClickHandler = (id: IRecommendation['atomCatalogueId']) => emit('click:assign', id);
    const onUnassignClickHandler = (id: IAssignment['assignmentUserId']) => emit('click:unassign', id);
    const onRecommendationClickHandler = (
      payload: IRecommendationCardClickPayload,
    ) => emit('click:recommendation', payload);

    return {
      onAssignClickHandler,
      onUnassignClickHandler,
      onRecommendationClickHandler,
    };
  },

});
