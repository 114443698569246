
import { defineComponent, PropType } from 'vue';

import { getImageUrl } from '@/helpers/gogha/getImageUrl';

import { isEmpty } from '@/helpers';
import ProvidersChip from '@/components/ui/ProvidersChip';
import { IRecommendation } from '@/services/api/lxp-assignment/types';

interface IData {
  hasImageLoadError: boolean;
}

export default defineComponent({
  name: 'NotMandatoryRecommendationCard',
  components: {
    ProvidersChip,
  },
  inject: ['Names'],
  props: {
    atom: {
      type: Object as PropType<IRecommendation>,
      required: true,
    },
  },

  data(): IData {
    return {
      hasImageLoadError: false,
    };
  },
  computed: {
    imageSrc(): string {
      return getImageUrl({
        uuid: this.atom.atomCatalogueId,
        type: 'course',
        name: 'cover',
        size: '92x92',
      });
    },
    isRecommendationEmpty(): boolean {
      return isEmpty(this.atom.atomName) && isEmpty(this.atom.atomCatalogueId);
    },
    hasProviders(): boolean {
      return this.atom.providers?.length > 0;
    },
    hasSteps(): boolean {
      return this.atom.stepsCount > 0;
    },
    isAdded(): boolean {
      return Boolean(this.atom?.assignmentUserId) ?? false;
    },
    isSelfAssigned(): boolean {
      return this.atom.selfAssigned ?? false;
    },
  },
  methods: {
    onAssignClickHandler() {
      this.$emit('click:assign', { id: this.atom.atomCatalogueId });
    },
    onRemoveAssignClickHandler() {
      this.$emit('click:remove-assign', { id: this.atom.assignmentUserId });
    },
    async goToTrackPreview() {
      if (this.isSelfAssigned || this.atom.assignmentUserId) {
        this.$emit('click:go-to-mandatory-atom', {
          assignmentUserId: this.atom.assignmentUserId,
          catalogueAtomMainId: this.atom.atomCatalogueId,
          playerSessionId: this.atom.playerSessionId,
          atomType: this.atom.atomType,
        });
      } else {
        this.$router.push({
          name: this.Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
          params: {
            catalogueAtomId: this.atom.atomCatalogueId,
          },
        });
      }
    },
  },
});
