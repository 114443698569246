/* eslint-disable no-param-reassign */
import axios from 'axios';
import apiRequestInterface from '@/services/api/interface';
import toSnakeCaseRequestTransformer from '@/services/api/interceptors/toSnakeCaseRequestTransformer';
import toCamelCaseResponseTransformer from '@/services/api/interceptors/toCamelCaseResponseTransformer';
import authorization from '@/services/api/interceptors/authorization';
import companyId from '@/services/api/interceptors/companyId';
import tos from '@/services/api/interceptors/tos';
import { onError } from '@/services/api/_utils/interceptors/onError';

const baseURL = process.env.VUE_APP_LXPMULTILEVELTEST_API_ADDRESS ?? 'http://localhost:3000/';

const config = {
  baseURL,
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  responseType: 'json',
  responseEncoding: 'utf8',
};

const transport = axios.create(config);

// IMPORTANT: default axios request transformer turns all data to strings, so at first use our to snake case
// transformer for arrays and objects
transport.defaults.transformRequest.unshift(toSnakeCaseRequestTransformer);
transport.defaults.transformResponse.push(toCamelCaseResponseTransformer);

// IMPORTANT: order for callbacks is REVERSE ORDER !!!
// transport.interceptors.request.use(accessToken);
transport.interceptors.request.use(authorization);
transport.interceptors.request.use(companyId);

// HACK: axios has bug with reverse order request interceptors
// see:
//  * https://github.com/axios/axios/issues/1663
//  * https://github.com/axios/axios/pull/2085
//
if (typeof transport.reverseInterceptorsOrder === 'function') {
  transport.reverseInterceptorsOrder();
} else {
  transport.interceptors.request.handlers.reverse();
}

// IMPORTANT: order for callbacks is DIRECT ORDER !!!
transport.interceptors.response.use(tos);
transport.interceptors.response.use(undefined, onError);

const error = (err) => err;
const response = (resp) => resp.data;

const service = apiRequestInterface(transport, response, error);

export default service;
