import {
  computed, ref, Ref, SetupContext,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { useDi } from '@/plugins';
import { useAlarm } from '@/composables';

import { DateTimeISO8601, PlayerSessionStatus } from '@/domains/common';
import { LevelEnum } from '@/domains/multileveltest/level';
import { IAnswer, IQuestion, IQuestionChoice } from '@/domains/multileveltest';

import * as LxpMultilevelService from '@/services/api/lxp-multi-level-test';

import Names from '@/plugins/vue-router/routeNames';
import { getRemainingTime } from '@/helpers/time';

interface IUseMultileveltestPlayerProps {
  playerSessionId: Ref<number>;
}

interface IMultileveltestTopic {
  id: number;
  name: string;
  topicResult: LevelEnum;
  allLevels: LevelEnum[];
  strong: boolean;
}

interface IMultileveltestRecommended {
  topicId: number;
  name: string;
}

export interface IMultileveltestContent {
  playerSessionStatus: PlayerSessionStatus;
  answerTimeout: number;
  finishedAt: DateTimeISO8601;
  result: {
    level: LevelEnum;
    topicsAll: IMultileveltestTopic[];
    recommended: IMultileveltestRecommended[];
  },
  maxLevel: LevelEnum;
  mandatoryRecommendation: boolean;
  question: IQuestion;
  answer: IAnswer;
  showRightAnswer: boolean;
}

export const useMultileveltestPlayer = (
  { playerSessionId }: IUseMultileveltestPlayerProps,
  context: SetupContext,
) => {
  const { notify } = useDi();
  const router = useRouter();

  const isLoading = ref(false);
  const content = ref<IMultileveltestContent>({
    playerSessionStatus: PlayerSessionStatus.UNKNOWN,
    answerTimeout: 0,
    finishedAt: '',
    result: {
      level: LevelEnum.LEVEL_INVALID,
      topicsAll: [],
      recommended: [],
    },
    maxLevel: LevelEnum.LEVEL_INVALID,
    mandatoryRecommendation: false,
    question: {} as IQuestion,
    answer: {} as IAnswer,
    showRightAnswer: false,
  });

  const questionStartedAt = ref<string>(new Date().toISOString());
  const maxSecondsCount = ref<number>(content.value.answerTimeout);

  const isSessionActive = computed(() => content.value.playerSessionStatus === PlayerSessionStatus.ACTIVE);
  const isSessionCompleted = computed(() => content.value.playerSessionStatus === PlayerSessionStatus.COMPLETED);
  const isSessionNew = computed(() => content.value.playerSessionStatus === PlayerSessionStatus.NEW);
  const isSessionStarted = computed(() => isSessionActive.value || isSessionCompleted.value);
  const testTimeout = computed(() => content.value.answerTimeout);

  const {
    isTimeOver,
    startTimer,
    timeLeft,
  } = useAlarm({ startedAt: questionStartedAt, maxSecondsCount: testTimeout });

  const isMandatoryRecommendation = computed(() => content.value.mandatoryRecommendation);

  const fetchSession = async () => {
    try {
      isLoading.value = true;

      const {
        maxLevel, finishedAt, result, playerSessionStatus, answerTimeout, mandatoryRecommendation,
      } = await LxpMultilevelService.testInfoGet({
        playerSessionId: playerSessionId.value,
      });

      content.value.playerSessionStatus = playerSessionStatus;
      content.value.answerTimeout = answerTimeout;
      content.value.result = result;
      content.value.maxLevel = maxLevel;
      content.value.finishedAt = finishedAt;
      content.value.mandatoryRecommendation = mandatoryRecommendation;
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };
  const runSession = async () => {
    try {
      const { status } = await LxpMultilevelService.testSessionRun({
        playerSessionId: playerSessionId.value,
      });

      content.value.playerSessionStatus = status;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchQuestion = async () => {
    try {
      const {
        question, answer, answerTimeout, showRightAnswer, playerSessionStatus,
      } = await LxpMultilevelService.testSessionQuestionGet({
        playerSessionId: playerSessionId.value,
      });

      content.value.question = question;
      content.value.answer = answer;
      content.value.answerTimeout = answerTimeout;
      content.value.showRightAnswer = showRightAnswer;
      content.value.playerSessionStatus = playerSessionStatus;
    } catch (e) {
      console.error(e);
    }
  };

  const startAnswer = async (answerId: number) => {
    try {
      const { startedAt } = await LxpMultilevelService.testSessionAnswerStart({
        playerSessionId: playerSessionId.value,
        answerId,
      });

      questionStartedAt.value = startedAt;
      context.emit('answer:start');
    } catch (e) {
      console.error(e);
    }
  };
  const updateAnswer = async (answerId: number, answers: Record<IQuestionChoice['id'], boolean>) => {
    try {
      await LxpMultilevelService.testSessionAnswerUpdate({
        playerSessionId: playerSessionId.value,
        answerId,
        answers,
      });

      context.emit('answer:update');
    } catch (e) {
      console.error(e);
      notify.error({
        content: (e as any).response.data.error.message,
      });
    }
  };
  const finishAnswer = async (answerId: number) => {
    try {
      const { playerSessionStatus } = await LxpMultilevelService.testSessionAnswerFinish({
        playerSessionId: playerSessionId.value,
        answerId,
      });

      context.emit('answer:finished');
      content.value.playerSessionStatus = playerSessionStatus;
    } catch (e) {
      console.error(e);
    }
  };

  const startQuestionAndTimer = async () => {
    try {
      await fetchQuestion();

      if (isSessionCompleted.value) {
        await router.replace({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT,
          params: {
            playerSessionId: String(playerSessionId.value),
          },
        });
      } else {
        const { startedAt } = content.value.answer;

        if (startedAt) {
          const remainingTime = getRemainingTime(startedAt, content.value.answerTimeout);
          maxSecondsCount.value = Math.round(remainingTime);

          if (remainingTime <= 0) {
            await finishAnswer(content.value.answer.id);
            await startQuestionAndTimer();
          }
        } else {
          await startAnswer(content.value.answer.id);
          maxSecondsCount.value = content.value.answerTimeout;
          startTimer();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return {
    isLoading,
    content,
    questionStartedAt,
    testTimeout,
    maxSecondsCount,

    isSessionStarted,
    isSessionActive,
    isSessionCompleted,
    isSessionNew,

    isTimeOver,
    timeLeft,

    isMandatoryRecommendation,

    fetchSession,
    runSession,
    fetchQuestion,
    startAnswer,
    updateAnswer,
    finishAnswer,
    startQuestionAndTimer,
  };
};
