import { DateTimeISO8601, UUID } from '@/domains/common';

export enum QuestionType {
  MONO = 'QUESTION_TYPE_MONO',
  POLY = 'QUESTION_TYPE_POLY',
}

export enum DialogActionTypes {
  NEXT = 'NEXT',
  CANCEL = 'CANCEL',
}

export interface IAnswerChoice {
  id: number,
  value: boolean
}

export interface IQuestionChoice {
  id: number,
  title: string,
  imageUuids: UUID[]
}

export interface IAnswer {
  id: number,
  status: string, // active, completed
  correctly: boolean, // верно ли отвечен (преодолен ли порог question_threshold)
  // eslint-disable-next-line camelcase
  startedAt: DateTimeISO8601 // Время начала,
  choices?: IAnswerChoice[]
}

export interface IQuestion {
  id: number,
  active?: boolean, // признак активности вопроса
  imageUuids: UUID[], // массив картинок
  questionType: QuestionType, // типа вопроса "QUESTION_TYPE_MONO" или "QUESTION_TYPE_POLY"
  title: string, // заголовок вопроса
  choices: IQuestionChoice[]
}

export type AnswersComponentData = {
  selectedMono: IQuestionChoice,
  selectedPoly: IQuestionChoice[],
}

export type AnswersComponentContext = AnswersComponentData & Vue
