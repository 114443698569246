
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import { ITestInfoGetResponseData as IMultileveltestResult } from '@/services/api/lxp-multi-level-test/types';
import { LevelEnum, LevelValuesMap } from '@/domains/multileveltest';
import LevelsScale from '@/components/ui/LevelsScale';
import ResultByTopic from '@/components/ui/ResultByTopic.vue';

export default defineComponent({
  name: 'MultileveltestResultInfo',

  components: {
    LevelsScale,
    ResultByTopic,
    TTPrompt,
  },

  props: {
    testInfo: {
      type: Object as PropType<IMultileveltestResult>,
      required: true,
    },
  },

  setup({ testInfo }) {
    const hasResult = computed(() => testInfo.result?.level && testInfo.result.level !== LevelEnum.LEVEL_INVALID);
    const maxLevelValue = computed(() => (
      // Значение по-умолчанию максимальное просто чтобы работало без данных хоть как-то адекватно
      LevelValuesMap[testInfo.maxLevel] ?? LevelValuesMap[LevelEnum.LEVEL_EXPERT]
    ));
    const currentLevel = computed(() => ({
      // LEVEL_NOVICE потому что иначе <LevelsScale /> неправильно работает
      type: testInfo.result?.level ?? LevelEnum.LEVEL_NOVICE,
      value: LevelValuesMap[testInfo.result?.level] ?? LevelValuesMap[LevelEnum.LEVEL_INVALID],
    }));

    const resultsByTopic = computed(() => testInfo.result?.topicsAll?.map((topic) => ({
      name: topic.name,
      currentLevel: topic.topicResult,
      isStrong: topic.strong,
      levels: topic.allLevels,
    })) ?? []);
    const attentionTopics = computed(() => resultsByTopic.value.filter((topic) => topic.isStrong === false));

    const expanded = ref(false);
    const visibleItems = 3;
    const isExpandButtonVisible = computed(() => resultsByTopic.value.length > visibleItems && !expanded.value);
    const visibleResultsByTopic = computed(() => (
      expanded.value
        ? resultsByTopic.value
        : resultsByTopic.value.slice(0, visibleItems)));

    return {
      hasResult,
      maxLevelValue,
      currentLevel,
      attentionTopics,
      resultsByTopic,
      expanded,
      visibleResultsByTopic,
      isExpandButtonVisible,
    };
  },
});
