import { computed, Ref, ref } from 'vue';
import { atomGet } from '@/services/api/catalogue';

import { IAtom, AtomType } from '@/domains/atom';
import Atom from '@/domains/atom/AtomEntity';

interface IUseCatalogueAtomProps {
  atomId: Ref<IAtom['id']>;
}

export function useCatalogueAtom({ atomId }: IUseCatalogueAtomProps) {
  const atom = ref<IAtom>(new Atom({}));
  const isLoading = ref<Boolean>(false);

  const atomType = computed<AtomType>(() => atom.value.atomName);

  const fetchAtom = async () => {
    if (!atomId) {
      console.error('Не могу получить атом - нет :atomId');
      return;
    }

    try {
      isLoading.value = true;
      const response = await atomGet(atomId.value);

      atom.value = response;
    } catch (e: any) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    atom,

    atomType,

    fetchAtom,
  };
}
