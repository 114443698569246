
import { defineComponent } from 'vue';

const CLASS_NAME = 'c-base-chip';

export default defineComponent({
  name: 'BaseChip',

  props: {
    /**
     * Определяет в каком стиле будет отображаться чип
     * @values default, success, danger, new-danger
     */
    type: {
      type: String,
      default: 'default',
      validator(value: string) {
        return ['default', 'success', 'danger', 'new-danger'].includes(value);
      },
    },
  },

  computed: {
    className(): Record<string, boolean> {
      return {
        [`${CLASS_NAME}--${this.type}`]: true,
      };
    },
  },
});
