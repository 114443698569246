
import Vue, { PropType } from 'vue';
import { Level, LevelEnum, LevelsBox } from '@/domains/multileveltest';

const LABEL_LEVEL_OFFSETS_MAP = {
  [LevelEnum.LEVEL_INVALID]: 0,
  [LevelEnum.LEVEL_NOVICE]: -5,
  [LevelEnum.LEVEL_BASIC]: -10,
  [LevelEnum.LEVEL_PROFICIENT]: 20,
  [LevelEnum.LEVEL_ADVANCED]: 30,
  [LevelEnum.LEVEL_EXPERT]: 0,
};

type PrimaryLineCalcObject = {
  type: LevelEnum,
  width: number;
  isCurrent: boolean;
}

export default Vue.extend({
  name: 'LevelsScale',
  props: {
    maxLevel: {
      type: Number,
      required: true,
    },
    currentLevel: {
      type: Object as PropType<Level>,
      default: () => ({
        type: LevelEnum.LEVEL_NOVICE,
        value: 0,
      }),
    },
    hasResult: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    levels() {
      return LevelsBox.filter(({ value }) => value <= this.maxLevel);
    },

    primaryLineCalc(): PrimaryLineCalcObject[] {
      const primaryLineCalc: PrimaryLineCalcObject[] = [];

      let initialWidth = 0;
      const diff = 100 / (this.levels.length - 1);

      this.levels.forEach(({ type }: { type: LevelEnum }) => {
        primaryLineCalc.push(
          {
            type,
            width: initialWidth,
            isCurrent: this.currentLevel.type === type,
          },
        );
        initialWidth += diff;
      });

      return primaryLineCalc;
    },

    primaryLineWidth(): number {
      const current = this.primaryLineCalc.find(({ isCurrent }) => isCurrent);
      return current?.width || 0;
    },

    primaryLineStyles(): { width: string } {
      return {
        width: `${this.primaryLineWidth}%`,
      };
    },

    currentUserLevelStyles(): { left: string } {
      const current = this.primaryLineCalc.find(({ isCurrent }) => isCurrent);

      return {
        left: `calc(${current?.width}% - 15px)`,
      };
    },
  },
  methods: {
    getLabelStyles(type: LevelEnum, index: number) {
      if (index === this.levels.length - 1) {
        return '0px';
      }

      return {
        left: `${LABEL_LEVEL_OFFSETS_MAP[type]}px`,
      };
    },

    getLabelClass(type: LevelEnum) {
      const classes = [];

      if (this.hasResult) {
        if (this.currentLevel.type === type) {
          classes.push('tt-light-mono-100--text');
        } else {
          classes.push('tt-light-mono-46--text');
        }
      } else {
        classes.push('tt-light-mono-46--text');
      }

      return classes;
    },

    getDotClass(level: Level) {
      const classes = [];

      if (this.isDotPrimary(level)) {
        classes.push('slider-dot__primary');
      } else {
        classes.push('slider-dot__secondary');
      }

      return classes;
    },

    isDotPrimary(level: Level) {
      return (level.value < this.currentLevel.value);
    },
  },
});
