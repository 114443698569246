export enum LevelEnum {
  LEVEL_INVALID = 'LEVEL_INVALID',
  LEVEL_NOVICE = 'LEVEL_NOVICE',
  LEVEL_BASIC = 'LEVEL_BASIC',
  LEVEL_PROFICIENT = 'LEVEL_PROFICIENT',
  LEVEL_ADVANCED = 'LEVEL_ADVANCED',
  LEVEL_EXPERT = 'LEVEL_EXPERT',
}
export interface Level {
  type: LevelEnum;
  value: number;
}

export const LevelNameMapBox = {
  [LevelEnum.LEVEL_INVALID]: 'Не оценен',
  [LevelEnum.LEVEL_NOVICE]: 'Начальный',
  [LevelEnum.LEVEL_BASIC]: 'Базовый',
  [LevelEnum.LEVEL_PROFICIENT]: 'Средний',
  [LevelEnum.LEVEL_ADVANCED]: 'Продвинутый',
  [LevelEnum.LEVEL_EXPERT]: 'Экспертный',
};

export const LevelValuesMap = {
  [LevelEnum.LEVEL_INVALID]: 0,
  [LevelEnum.LEVEL_NOVICE]: 0,
  [LevelEnum.LEVEL_BASIC]: 1,
  [LevelEnum.LEVEL_PROFICIENT]: 2,
  [LevelEnum.LEVEL_ADVANCED]: 3,
  [LevelEnum.LEVEL_EXPERT]: 4,
};

export const LevelsBox = [
  {
    type: LevelEnum.LEVEL_NOVICE,
    text: LevelNameMapBox[LevelEnum.LEVEL_NOVICE],
    value: LevelValuesMap[LevelEnum.LEVEL_NOVICE],
  },
  {
    type: LevelEnum.LEVEL_BASIC,
    text: LevelNameMapBox[LevelEnum.LEVEL_BASIC],
    value: LevelValuesMap[LevelEnum.LEVEL_BASIC],
  },
  {
    type: LevelEnum.LEVEL_PROFICIENT,
    text: LevelNameMapBox[LevelEnum.LEVEL_PROFICIENT],
    value: LevelValuesMap[LevelEnum.LEVEL_PROFICIENT],
  },
  {
    type: LevelEnum.LEVEL_ADVANCED,
    text: LevelNameMapBox[LevelEnum.LEVEL_ADVANCED],
    value: LevelValuesMap[LevelEnum.LEVEL_ADVANCED],
  },
  {
    type: LevelEnum.LEVEL_EXPERT,
    text: LevelNameMapBox[LevelEnum.LEVEL_EXPERT],
    value: LevelValuesMap[LevelEnum.LEVEL_EXPERT],
  },
];
