
import Vue from 'vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';

export default Vue.extend({
  name: 'TTLChip',

  mixins: [TTChip],

  props: {
    maxHeight: {
      type: String,
      default: '',
    },

    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    maxHeightValue(): string {
      if (this.maxHeight) {
        return `max-height: ${this.maxHeight}`;
      }

      if (this.large) {
        return 'max-height: 28px';
      }

      return 'max-height: 20px';
    },
  },
});
