
import { defineComponent, PropType } from 'vue';

import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import TTLChip from '@/components/ui/TTLChip/TTLChip.vue';

import AssignmentTrackCardDeadline from '@/components/tracks/AssignmentTrackCardDeadline/AssignmentTrackCardDeadline.vue';
import { IRecommendation } from '@/services/api/lxp-assignment/types';

interface IData {
  hasImageLoadError: boolean;
}

export default defineComponent({
  name: 'MandatoryRecommendationCard',
  components: { AssignmentTrackCardDeadline, TTLChip },
  inject: ['Names'],
  props: {
    atom: {
      type: Object as PropType<IRecommendation>,
      required: true,
    },
  },
  data(): IData {
    return {
      hasImageLoadError: false,
    };
  },
  computed: {
    imageSrc(): string {
      return getImageUrl({
        uuid: this.atom.atomCatalogueId,
        type: 'course',
        name: 'cover',
        size: '92x92',
      });
    },
    isTrackFinished(): boolean {
      return !!this.atom.finishDate;
    },
    dataTestLabel(): string {
      return `atom-${this.atom.atomName}`;
    },
  },
  methods: {
    onClickGoToHandler() {
      this.$emit('click:go-to-mandatory-atom', {
        assignmentUserId: this.atom.assignmentUserId,
        catalogueAtomMainId: this.atom.atomCatalogueId,
        playerSessionId: this.atom.playerSessionId,
        atomType: this.atom.atomType,
      });
    },
  },
});
