
import {
  computed,
  defineComponent, onMounted, ref, toRefs, watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';
import { Names } from '@/plugins/vue-router';
import { AtomType } from '@/domains/atom';
import { useMultileveltestPlayer } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { useAssignment } from '@/domains/assignment/composables';
import UpButton from '@/components/ui/UpButton.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar/AtomPlayerSidebar.vue';
import MultileveltestResultView from '@/views/App/Learning/Atoms/multileveltest/MultileveltestResultView';
import { IRecommendationCardClickPayload } from '@/domains/track';
import { IRecommendation } from '@/services/api/lxp-assignment/types';
import { IAssignment } from '@/domains/assignment';
import { noop } from '@/helpers';

export default defineComponent({
  name: 'MultileveltestResult',

  components: {
    TTBackButton,
    UpButton,
    AtomPlayerSidebar,
    MultileveltestResultView,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n();
    const router = useRouter();
    const { playerSessionId } = toRefs(props);
    const isComponentLoading = ref(true);

    const {
      isLoading: isMultileveltestLoading,
      content,
      isSessionCompleted,
      isMandatoryRecommendation,
      fetchSession,
    } = useMultileveltestPlayer({ playerSessionId }, context);

    const {
      isLoading: isAssignmentLoading,
      // assignment,
      catalogueAtomVersionId,
      recommendations,
      fetchAssignmentBySession,
      getRecommendations,
      onAssignHandler,
      onUnassignHandler,
    } = useAssignment({
      playerSessionId,
      atomType: AtomType.MULTILEVELTEST,
      isMandatoryRecommendation,
    });

    const {
      atom, // TODO - брать из assignment - https://burning-heart.atlassian.net/browse/NEON-2238
      isLoading: isAtomLoading,
      fetchAtom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const breadcrumbs = computed(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
      },
      {
        text: atom.value.name, // TODO - брать из assignment - https://burning-heart.atlassian.net/browse/NEON-2238
        disabled: true,
      },
    ]);

    const step = computed(() => ({
      atomType: AtomType.MULTILEVELTEST,
      name: atom.value.name, // TODO - брать из assignment - https://burning-heart.atlassian.net/browse/NEON-2238
      isCompleted: isSessionCompleted.value,
      id: atom.value?.id ?? '',
    }));

    const isLoading = computed(() => (
      isComponentLoading.value
      || isMultileveltestLoading.value
      || isAssignmentLoading.value
      || isAtomLoading.value));

    const init = async () => {
      Promise.all([
        fetchSession()
          .then(getRecommendations),
        fetchAssignmentBySession()
          .then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isComponentLoading.value = false;
        });
    };

    onMounted(() => init());
    watch(playerSessionId, () => init());

    const onAssignClickHandler = async (id: IRecommendation['atomCatalogueId']) => {
      await onAssignHandler({
        paths: { catalogueAtomId: id },
        data: {
          onRecommendation: true,
        },
      });
      await getRecommendations();
    };

    const onUnassignClickHandler = async (id: IAssignment['assignmentUserId']) => {
      await onUnassignHandler({
        paths: { assignmentUserId: id },
      });
      await getRecommendations();
    };

    const onRecommendationClickHandler = async (
      payload: IRecommendationCardClickPayload,
    ) => {
      if (payload.playerSessionId) {
        return router.push({
          name: Names.R_APP_LEARNING_SESSION_GATEWAY,
          params: {
            playerSessionId: String(payload.playerSessionId),
            atomType: payload.atomType!,
          },
        }).catch(noop);
      }

      return router.push({
        name: Names.R_APP_LEARNING_ASSIGNMENT_RUN,
        params: {
          assignmentId: String(payload.assignmentUserId),
          catalogueAtomMainId: String(payload.catalogueAtomMainId),
        },
      }).catch(noop);
    };

    return {
      isLoading,
      atom,
      content,
      breadcrumbs,
      step,
      recommendations,
      isMandatoryRecommendation,
      onAssignClickHandler,
      onUnassignClickHandler,
      onRecommendationClickHandler,
    };
  },
});
