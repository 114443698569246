
import Vue, { PropType } from 'vue';

import { getProviderImageUrl } from '@/domains/assignment';

import { UUID } from '@/domains/common';
import { Banner } from '@/types/banner';
import BaseChip from '@/components/ui/BaseChip';
import TrackMulticontentProvidersModal
  from '@/components/assignment/TrackMulticontentProvidersModal/TrackMulticontentProvidersModal.vue';
import { IContentProvider } from '@/domains/content-provider';

export default Vue.extend({
  name: 'ProvidersChip',

  components: {
    TrackMulticontentProvidersModal,
    BaseChip,
  },

  props: {
    contentProviders: {
      type: Array as PropType<IContentProvider[]>,
      required: true,
    },

    dataTestAvatar: {
      type: String as PropType<String>,
      default: '',
    },
  },

  computed: {
    isMultipleProviders(): boolean {
      return this.contentProviders.length > 1;
    },

    firstContentProvider(): IContentProvider | null {
      const [first = null] = this.contentProviders;
      return first;
    },
  },

  methods: {
    openDialog() {
      if (this.isMultipleProviders) {
        (this.$refs.dialogProviders as Banner).open();
      }
    },

    getProviderIcon(uuid: UUID) {
      if (uuid) {
        return getProviderImageUrl(uuid, '46x46');
      }
      return '';
    },
  },
});
