
import Vue, { PropType } from 'vue';
import { LevelEnum } from '@/domains/multileveltest';

export default Vue.extend({
  name: 'ResultByTopic',
  props: {
    name: {
      type: String,
      default: '',
    },
    currentLevel: {
      type: String as PropType<LevelEnum>,
      default: LevelEnum.LEVEL_INVALID,
    },
    levels: {
      type: Array,
      default: [] as PropType<LevelEnum[]>,
    },
    isStrong: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    chipResult(): { text: string, dataTestLabel: string } {
      return this.isStrong
        ? {
          text: this.$t('ResultByTopic.goodResult'),
          dataTestLabel: 'chip-good-result',
        }
        : {
          text: this.$t('ResultByTopic.improveZone'),
          dataTestLabel: 'chip-bad-result',
        };
    },

    chipColor(): string {
      return this.isStrong ? 'tt-light-green pale' : 'tt-light-mono-4';
    },
    colorText(): string {
      return this.isStrong ? 'tt-light-green--text' : 'tt-light-mono-64--text';
    },
  },
  methods: {
    levelNamesMap(level: LevelEnum): string {
      const LevelNameMap: Record<LevelEnum, string> = {
        [LevelEnum.LEVEL_INVALID]: '',
        [LevelEnum.LEVEL_NOVICE]: this.$t('LevelNames.novice'),
        [LevelEnum.LEVEL_BASIC]: this.$t('LevelNames.basic'),
        [LevelEnum.LEVEL_PROFICIENT]: this.$t('LevelNames.proficient'),
        [LevelEnum.LEVEL_ADVANCED]: this.$t('LevelNames.advanced'),
        [LevelEnum.LEVEL_EXPERT]: this.$t('LevelNames.expert'),
      };
      return LevelNameMap[level];
    },
  },
});
