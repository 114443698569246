import transport from '@/services/api/lxp-multi-level-test/transport';
import * as methods from '@/services/api/constants';

import {
  ITestSessionAnswerFinishRequestPayload,
  ITestSessionAnswerFinishResponseData,
  ITestSessionAnswerStartRequestPayload,
  ITestSessionAnswerStartResponseData,
  ITestSessionAnswerUpdateRequestPayload,
  ITestSessionAnswerUpdateResponse,
  ITestSessionQuestionsGetRequestPayload,
  ITestSessionQuestionsGetResponseData,
  ITestInfoGetResponseData,
  ITestSessionRunResponseData,
} from './types';

export const testSessionQuestionGet = async ({ playerSessionId }: ITestSessionQuestionsGetRequestPayload):
Promise<ITestSessionQuestionsGetResponseData> => transport({
  url: `/v3/tests/player/${playerSessionId}/questions`,
  method: methods.HTTP_GET,
});

export const testSessionAnswerStart = (
  { playerSessionId, answerId }: ITestSessionAnswerStartRequestPayload,
): Promise<ITestSessionAnswerStartResponseData> => transport({
  url: `/v3/tests/player/${playerSessionId}/start_timer/${answerId}`,
  method: methods.HTTP_POST,
});

export const testSessionAnswerUpdate = (
  { playerSessionId, answerId, answers }: ITestSessionAnswerUpdateRequestPayload,
): Promise<ITestSessionAnswerUpdateResponse> => transport({
  url: `/v3/tests/player/${playerSessionId}/answers/${answerId}`,
  method: methods.HTTP_PUT,
  data: { answers },
});

export const testSessionAnswerFinish = (
  { playerSessionId, answerId }: ITestSessionAnswerFinishRequestPayload,
): Promise<ITestSessionAnswerFinishResponseData> => transport({
  url: `/v3/tests/player/${playerSessionId}/answers/${answerId}/finish`,
  method: methods.HTTP_POST,
});

// eslint-disable-next-line import/prefer-default-export
export const testInfoGet = async (
  { playerSessionId }: { playerSessionId: number },
): Promise<ITestInfoGetResponseData> => transport({
  url: `/v3/tests/player/${playerSessionId}/info`,
  method: methods.HTTP_GET,
});

export const testSessionRun = async (
  { playerSessionId }: { playerSessionId: number },
): Promise<ITestSessionRunResponseData> => transport({
  url: `/v3/tests/player/${playerSessionId}/run`,
  method: methods.HTTP_POST,
});
