
import Vue, { PropType } from 'vue';

import TTLChip from '@/components/ui/TTLChip/TTLChip.vue';

// TODO: Добавить сторис и тесты к компоненту AssignmentTrackCardDeadline

export default Vue.extend({
  name: 'AssignmentTrackCardDeadline',
  components: { TTLChip },
  props: {
    finishDate: {
      type: String as PropType<string>,
      default: '',
    },
    finishedStepsCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    totalStepsCount: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  computed: {
    deadlineHumanized(): string {
      const deadline = this.$dayjs(this.finishDate);

      if (deadline.isBefore(this.$dayjs())) {
        return this.$t('AssignmentTrackCardDeadline.failed');
      }

      if (deadline.isToday()) {
        return this.$t('AssignmentTrackCardDeadline.completeToday');
      }

      if (deadline.isTomorrow()) {
        // eslint-disable-next-line max-len
        return this.$t('AssignmentTrackCardDeadline.completeTomorrow', { date: deadline.format('HH:mm') });
      }

      return this.$t('AssignmentTrackCardDeadline.completeBefore', { date: deadline.format('D MMMM') });
    },
    isExpires(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return deadline.diff(today, 'week') < 1;
    },
    isCompleted(): boolean {
      if (this.totalStepsCount || this.finishedStepsCount) {
        return this.totalStepsCount - this.finishedStepsCount <= 0;
      }
      return false;
    },
    isFailed(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return !this.isCompleted && today.isAfter(deadline, 'days');
    },
    info(): any {
      const resolver = [
        {
          value: this.isFailed,
          icon: 'fas fa-exclamation-circle',
          color: {
            icon: 'tt-light-red',
            text: 'tt-light-red--text',
          },
          text: this.$t('AssignmentTrackCardDeadline.failed'),
        },
        {
          value: this.isCompleted,
          icon: 'fas fa-check-circle',
          color: {
            icon: 'tt-light-green vibrant',
            text: 'tt-light-mono-64--text',
          },
          text: this.$t('AssignmentTrackCardDeadline.completed'),
        },
        {
          value: this.isExpires,
          icon: 'fas fa-calendar-alt',
          color: {
            icon: 'tt-light-red',
            text: 'tt-light-red--text',
          },
          text: this.deadlineHumanized,
        },
        {
          value: true,
          icon: 'fas fa-calendar-alt',
          color: {
            icon: 'tt-light-mono-24',
            text: 'tt-light-mono-64--text',
          },
          text: this.deadlineHumanized,
        },
      ];

      return resolver.find((i) => i.value);
    },
  },
});
